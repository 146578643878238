import React, { FC } from "react";
import Link from "next/link";
import styled from "styled-components";
import Content from "components/front/corporate/atoms/Content";
import Headline from "components/front/corporate/atoms/Headline";

export type Props = {}
const ServiceSection: FC<Props> = ({ ...props }) => {
  return (
    <section {...props}>
      <Headline>サービス</Headline>
      <ServiceList>
        <Service>
          <ServiceImage src="/image/corporate/service-crowd-funding.png" loading="lazy" />
          <Link href="/">
            <LinkButton>アニメ特化型クラファンへ</LinkButton>
          </Link>
        </Service>
        <Service>
          <ServiceImage src="/image/corporate/service-hometown-tax.png" loading="lazy" />
          <Link href="/">
            <LinkButton>アニメふるさと納税へ</LinkButton>
          </Link>
        </Service>
      </ServiceList>
    </section>
  );
};
export default ServiceSection;

const ServiceList = styled(Content)`
  @media screen and (min-width: 961px) {
    display: flex;
  }
`;
const Service = styled.div`
  @media screen and (max-width: 960px) {
    margin-bottom: 40px;
  }
  @media screen and (min-width: 961px) {
    width: 490px;
    &:not(:first-of-type) {
      margin-left: 20px;
    }
  }
`;
const ServiceImage = styled.img`
  border: 1px solid #666;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
  @media screen and (min-width: 961px) {
    width: 490px;
  }
`;
const LinkButton = styled.a`
  text-align: center;
  line-height: 1.428em;
  cursor: pointer;
  background-image: url(/img/header_login_bg--pc.png);
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  @media screen and (max-width: 960px) {
    width: 247px;
    height: 55px;
  }
  @media screen and (min-width: 961px) {
    width: 309px;
    height: 69px;
    font-size: 20px;
  }
`;
